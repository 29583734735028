/* istanbul ignore file */
import React from 'react'
import PropTypes from 'prop-types'

import {
  construct,
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
} from './MapChildHelper'

import { MAP, MARKER, ANCHOR, MARKER_CLUSTERER } from './constants'

const markerPropTypes = {
    noRedraw: PropTypes.bool,
    defaultAnimation: PropTypes.any,
    defaultClickable: PropTypes.bool,
    defaultCursor: PropTypes.string,
    defaultDraggable: PropTypes.bool,
    defaultIcon: PropTypes.any,
    defaultLabel: PropTypes.any,
    defaultOpacity: PropTypes.number,
    defaultOptions: PropTypes.any,
    defaultPlace: PropTypes.any,
    defaultPosition: PropTypes.any,
    defaultShape: PropTypes.any,
    defaultTitle: PropTypes.string,
    defaultVisible: PropTypes.bool,
    defaultZIndex: PropTypes.number,
    animation: PropTypes.any,
    clickable: PropTypes.bool,
    cursor: PropTypes.string,
    draggable: PropTypes.bool,
    icon: PropTypes.any,
    label: PropTypes.any,
    opacity: PropTypes.number,
    options: PropTypes.any,
    place: PropTypes.any,
    position: PropTypes.any,
    shape: PropTypes.any,
    title: PropTypes.string,
    visible: PropTypes.bool,
    zIndex: PropTypes.number,
    onDblClick: PropTypes.func,
    onDragEnd: PropTypes.func,
    onDragStart: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseOut: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseUp: PropTypes.func,
    onRightClick: PropTypes.func,
    onAnimationChanged: PropTypes.func,
    onClick: PropTypes.func,
    onClickableChanged: PropTypes.func,
    onCursorChanged: PropTypes.func,
    onDrag: PropTypes.func,
    onDraggableChanged: PropTypes.func,
    onFlatChanged: PropTypes.func,
    onIconChanged: PropTypes.func,
    onPositionChanged: PropTypes.func,
    onShapeChanged: PropTypes.func,
    onTitleChanged: PropTypes.func,
    onVisibleChanged: PropTypes.func,
    onZindexChanged: PropTypes.func,
}

export class Marker extends React.PureComponent {
    static propTypes = {
        noRedraw: PropTypes.bool,
        ...markerPropTypes,
    }

    static contextTypes = {
        [MAP]: PropTypes.object,
        [MARKER_CLUSTERER]: PropTypes.object,
    }

    static childContextTypes = {
        [ANCHOR]: PropTypes.object,
    }

    constructor (props, context) {
        super(props, context)
        // eslint-disable-next-line no-undef
        const marker = new google.maps.Marker()
        construct(Marker.propTypes, updaterMap, this.props, marker)
        const markerClusterer = this.context[MARKER_CLUSTERER]
        if (markerClusterer) {
            markerClusterer.addMarker(marker, !!this.props.noRedraw)
        } else {
            marker.setMap(this.context[MAP])
        }
        this.state = {
            [MARKER]: marker,
        }
    }

    getChildContext () {
        return {
        [ANCHOR]: this.context[ANCHOR] || this.state[MARKER],
        }
    }

    componentDidMount () {
        componentDidMount(this, this.state[MARKER], eventMap)
    }

    componentDidUpdate (prevProps) {
        componentDidUpdate(
        this,
        this.state[MARKER],
        eventMap,
        updaterMap,
        prevProps
        )
    }

    componentWillUnmount () {
        componentWillUnmount(this)
        const marker = this.state[MARKER]
        if (marker) {
        const markerClusterer = this.context[MARKER_CLUSTERER]
        if (markerClusterer) {
            markerClusterer.removeMarker(marker, !!this.props.noRedraw)
        }
        marker.setMap(null)
        }
    }

    render () {
        return <div>{this.props.children}</div>
    }

    getAnimation () {
        return this.state[MARKER].getAnimation()
    }

    getClickable () {
        return this.state[MARKER].getClickable()
    }

    getCursor () {
        return this.state[MARKER].getCursor()
    }

    getDraggable () {
        return this.state[MARKER].getDraggable()
    }

    getIcon () {
        return this.state[MARKER].getIcon()
    }

    getLabel () {
        return this.state[MARKER].getLabel()
    }

    getOpacity () {
        return this.state[MARKER].getOpacity()
    }

    getPlace () {
        return this.state[MARKER].getPlace()
    }

    getPosition () {
        return this.state[MARKER].getPosition()
    }

    getShape () {
        return this.state[MARKER].getShape()
    }

    getTitle () {
        return this.state[MARKER].getTitle()
    }

    getVisible () {
        return this.state[MARKER].getVisible()
    }

    getZIndex () {
        return this.state[MARKER].getZIndex()
    }
    }

    export default Marker

    const eventMap = {
    onDblClick: 'dblclick',
    onDragEnd: 'dragend',
    onDragStart: 'dragstart',
    onMouseDown: 'mousedown',
    onMouseOut: 'mouseout',
    onMouseOver: 'mouseover',
    onMouseUp: 'mouseup',
    onRightClick: 'rightclick',
    onAnimationChanged: 'animation_changed',
    onClick: 'click',
    onClickableChanged: 'clickable_changed',
    onCursorChanged: 'cursor_changed',
    onDrag: 'drag',
    onDraggableChanged: 'draggable_changed',
    onFlatChanged: 'flat_changed',
    onIconChanged: 'icon_changed',
    onPositionChanged: 'position_changed',
    onShapeChanged: 'shape_changed',
    onTitleChanged: 'title_changed',
    onVisibleChanged: 'visible_changed',
    onZindexChanged: 'zindex_changed',
    }

    const updaterMap = {
    animation (instance, animation) {
        instance.setAnimation(animation)
    },

    clickable (instance, clickable) {
        instance.setClickable(clickable)
    },

    cursor (instance, cursor) {
        instance.setCursor(cursor)
    },

    draggable (instance, draggable) {
        instance.setDraggable(draggable)
    },

    icon (instance, icon) {
        instance.setIcon(icon)
    },

    label (instance, label) {
        instance.setLabel(label)
    },

    opacity (instance, opacity) {
        instance.setOpacity(opacity)
    },

    options (instance, options) {
        instance.setOptions(options)
    },

    place (instance, place) {
        instance.setPlace(place)
    },

    position (instance, position) {
        instance.setPosition(position)
    },

    shape (instance, shape) {
        instance.setShape(shape)
    },

    title (instance, title) {
        instance.setTitle(title)
    },

    visible (instance, visible) {
        instance.setVisible(visible)
    },

    zIndex (instance, zIndex) {
        instance.setZIndex(zIndex)
    },
}
