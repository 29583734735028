/* istanbul ignore file */
import React from 'react'
import PropTypes from 'prop-types'

import {
    construct,
    componentDidMount,
    componentDidUpdate,
    componentWillUnmount,
} from './MapChildHelper'
import { warn } from '../../util/logger'
import { MAP } from './constants'

const mapPropTypes = {
    children: PropTypes.any,
    defaultExtraMapTypes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.any)),
    defaultCenter: PropTypes.any,
    defaultClickableIcons: PropTypes.bool,
    defaultHeading: PropTypes.number,
    defaultMapTypeId: PropTypes.any,
    defaultOptions: PropTypes.any,
    defaultStreetView: PropTypes.any,
    defaultTilt: PropTypes.number,
    defaultZoom: PropTypes.number,
    center: PropTypes.any,
    clickableIcons: PropTypes.bool,
    heading: PropTypes.number,
    mapTypeId: PropTypes.any,
    options: PropTypes.any,
    streetView: PropTypes.any,
    tilt: PropTypes.number,
    zoom: PropTypes.number,
    onDblClick: PropTypes.func,
    onDragEnd: PropTypes.func,
    onDragStart: PropTypes.func,
    onMapTypeIdChanged: PropTypes.func,
    onMouseMove: PropTypes.func,
    onMouseOut: PropTypes.func,
    onMouseOver: PropTypes.func,
    onRightClick: PropTypes.func,
    onTilesLoaded: PropTypes.func,
    onBoundsChanged: PropTypes.func,
    onCenterChanged: PropTypes.func,
    onClick: PropTypes.func,
    onDrag: PropTypes.func,
    onHeadingChanged: PropTypes.func,
    onIdle: PropTypes.func,
    onProjectionChanged: PropTypes.func,
    onResize: PropTypes.func,
    onTiltChanged: PropTypes.func,
    onZoomChanged: PropTypes.func,
}

export class GoogleMaps extends React.PureComponent {
    static contextTypes = {
        [MAP]: PropTypes.object,
    }

    static propTypes = {
        children: PropTypes.any,
        ...mapPropTypes,
    }

    fitBounds (...args) {
        return this.context[MAP].fitBounds(...args)
    }
    panBy (...args) {
        return this.context[MAP].panBy(...args)
    }
    panTo (...args) {
        return this.context[MAP].panTo(...args)
    }
    panToBounds (...args) {
        return this.context[MAP].panToBounds(...args)
    }

    componentDidMount () {
        componentDidMount(this, this.context[MAP], eventMap)
    }

    componentDidUpdate (props) {
        componentDidUpdate(this, this.context[MAP], eventMap, updaterMap, props)
    }

    componentWillUnmount () {
        componentWillUnmount(this)
    }

    constructor (props, context) {
        super(props, context)
        !context[MAP] && warn(
            `Did you wrap <GoogleMap> component with withGoogleMap() HOC?`
        )
        construct(mapPropTypes, updaterMap, props, this.context[MAP])
    }

    render () {
        return <div>{this.props.children}</div>
    }

    getBounds () {
        return this.context[MAP].getBounds()
    }

    getCenter () {
        return this.context[MAP].getCenter()
    }

    getClickableIcons () {
        return this.context[MAP].getClickableIcons()
    }

    getDiv () {
        return this.context[MAP].getDiv()
    }

    getHeading () {
        return this.context[MAP].getHeading()
    }

    getMapTypeId () {
        return this.context[MAP].getMapTypeId()
    }

    getProjection () {
        return this.context[MAP].getProjection()
    }

    getStreetView () {
        return this.context[MAP].getStreetView()
    }

    getTilt () {
        return this.context[MAP].getTilt()
    }

    getZoom () {
        return this.context[MAP].getZoom()
    }
}

export default GoogleMaps

const eventMap = {
    onDblClick: 'dblclick',
    onDragEnd: 'dragend',
    onDragStart: 'dragstart',
    onMapTypeIdChanged: 'maptypeid_changed',
    onMouseMove: 'mousemove',
    onMouseOut: 'mouseout',
    onMouseOver: 'mouseover',
    onRightClick: 'rightclick',
    onTilesLoaded: 'tilesloaded',
    onBoundsChanged: 'bounds_changed',
    onCenterChanged: 'center_changed',
    onClick: 'click',
    onDrag: 'drag',
    onHeadingChanged: 'heading_changed',
    onIdle: 'idle',
    onProjectionChanged: 'projection_changed',
    onResize: 'resize',
    onTiltChanged: 'tilt_changed',
    onZoomChanged: 'zoom_changed',
}

const updaterMap = {
    extraMapTypes (instance, extra) {
        extra.forEach(it => instance.mapTypes.set(...it))
    },

    center (instance, center) {
        instance.setCenter(center)
    },

    clickableIcons (instance, clickableIcons) {
        instance.setClickableIcons(clickableIcons)
    },

    heading (instance, heading) {
        instance.setHeading(heading)
    },

    mapTypeId (instance, mapTypeId) {
        instance.setMapTypeId(mapTypeId)
    },

    options (instance, options) {
        instance.setOptions(options)
    },

    streetView (instance, streetView) {
        instance.setStreetView(streetView)
    },

    tilt (instance, tilt) {
        instance.setTilt(tilt)
    },

    zoom (instance, zoom) {
        instance.setZoom(zoom)
    },
}
