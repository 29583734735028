/* istanbul ignore file */
import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { warn } from '../../util/logger'

import {
  construct,
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
} from './MapChildHelper'

import { MAP, ANCHOR, INFO_BOX } from './constants'

const infoBoxPropTypes = {
    defaultOptions: PropTypes.any,
    defaultPosition: PropTypes.any,
    defaultVisible: PropTypes.bool,
    defaultZIndex: PropTypes.number,
    options: PropTypes.any,
    position: PropTypes.any,
    visible: PropTypes.bool,
    zIndex: PropTypes.number,
    onCloseClick: PropTypes.func,
    onDomReady: PropTypes.func,
    onContentChanged: PropTypes.func,
    onPositionChanged: PropTypes.func,
    onZindexChanged: PropTypes.func,
}

export class InfoBox extends React.PureComponent {
    static propTypes = {
        ...infoBoxPropTypes,
    }

    static contextTypes = {
        [MAP]: PropTypes.object,
        [ANCHOR]: PropTypes.object,
    }

    constructor (props, context) {
        super(props)
        const {
            InfoBox: GoogleMapsInfobox,
        } = require(/* 'google-maps-infobox' uses 'google' as a global variable. Since we don't
            * have 'google' on the server, we can not use it in server-side rendering.
            * As a result, we import 'google-maps-infobox' here to prevent an error on
            * a isomorphic server.
            */ `google-maps-infobox`)
        const infoBox = new GoogleMapsInfobox()
        construct(infoBoxPropTypes, updaterMap, this.props, infoBox)
        infoBox.setMap(context[MAP])
        this.state = { [INFO_BOX]: infoBox }
    }

    componentDidMount () {
        componentDidMount(this, this.state[INFO_BOX], eventMap)
        const content = document.createElement(`div`)
        ReactDOM.unstable_renderSubtreeIntoContainer(
            this,
            React.Children.only(this.props.children),
            content
        )
        this.state[INFO_BOX].setContent(content)
        open(this.state[INFO_BOX], this.context[ANCHOR])
    }

    componentDidUpdate (prevProps) {
        componentDidUpdate(
            this,
            this.state[INFO_BOX],
            eventMap,
            updaterMap,
            prevProps
        )
        if (this.props.children !== prevProps.children) {
            ReactDOM.unstable_renderSubtreeIntoContainer(
                this,
                React.Children.only(this.props.children),
                this.state[INFO_BOX].getContent()
            )
        }
    }

    componentWillUnmount () {
        componentWillUnmount(this)
        const infoBox = this.state[INFO_BOX]
        if (infoBox) {
            if (infoBox.getContent()) {
                ReactDOM.unmountComponentAtNode(infoBox.getContent())
            }
            infoBox.setMap(null)
        }
    }

    render () {
        return false
    }

    getPosition () {
        return this.state[INFO_BOX].getPosition()
    }

    getVisible () {
        return this.state[INFO_BOX].getVisible()
    }

    getZIndex () {
        return this.state[INFO_BOX].getZIndex()
    }
}

export default InfoBox

const open = (infoBox, anchor) => {
    if (anchor) {
        infoBox.open(infoBox.getMap(), anchor)
    } else if (infoBox.getPosition()) {
        infoBox.open(infoBox.getMap())
    } else {
        warn(
            `You must provide either an anchor (typically render it inside a <Marker>) or a position props for <InfoBox>.`
        )
    }
}

const eventMap = {
    onCloseClick: 'closeclick',
    onDomReady: 'domready',
    onContentChanged: 'content_changed',
    onPositionChanged: 'position_changed',
    onZindexChanged: 'zindex_changed',
}

const updaterMap = {
    options (instance, options) {
        instance.setOptions(options)
    },

    position (instance, position) {
        instance.setPosition(position)
    },

    visible (instance, visible) {
        instance.setVisible(visible)
    },

    zIndex (instance, zIndex) {
        instance.setZIndex(zIndex)
    },
}
