/* istanbul ignore file */
import React from 'react'
import PropTypes from 'prop-types'

import {
  construct,
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
} from './MapChildHelper'

import { MAP, POLYLINE } from './constants'

 const polylinePropTypes = {
    defaultDraggable: PropTypes.bool,
    defaultEditable: PropTypes.bool,
    defaultOptions: PropTypes.any,
    defaultPath: PropTypes.any,
    defaultVisible: PropTypes.bool,
    draggable: PropTypes.bool,
    editable: PropTypes.bool,
    options: PropTypes.any,
    path: PropTypes.any,
    visible: PropTypes.bool,
    onDblClick: PropTypes.func,
    onDragEnd: PropTypes.func,
    onDragStart: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseMove: PropTypes.func,
    onMouseOut: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseUp: PropTypes.func,
    onRightClick: PropTypes.func,
    onClick: PropTypes.func,
    onDrag: PropTypes.func,
}

export class Polyline extends React.PureComponent {
    static propTypes = {
        ...polylinePropTypes,
    }

    static contextTypes = {
        [MAP]: PropTypes.object,
    }

    constructor (props, context) {
        super(props, context)
        // eslint-disable-next-line no-undef
        const polyline = new google.maps.Polyline()
        construct(Polyline.propTypes, updaterMap, this.props, polyline)
        polyline.setMap(this.context[MAP])
        this.state = {
        [POLYLINE]: polyline,
        }
    }

    componentDidMount () {
        componentDidMount(this, this.state[POLYLINE], eventMap)
    }

    componentDidUpdate (prevProps) {
        componentDidUpdate(
        this,
        this.state[POLYLINE],
        eventMap,
        updaterMap,
        prevProps
        )
    }

    componentWillUnmount () {
        componentWillUnmount(this)
        const polyline = this.state[POLYLINE]
        if (polyline) {
        polyline.setMap(null)
        }
    }

    render () {
        return false
    }

    getDraggable () {
        return this.state[POLYLINE].getDraggable()
    }

    getEditable () {
        return this.state[POLYLINE].getEditable()
    }

    getPath () {
        return this.state[POLYLINE].getPath()
    }

    getVisible () {
        return this.state[POLYLINE].getVisible()
    }
}

export default Polyline

const eventMap = {
  onDblClick: 'dblclick',
  onDragEnd: 'dragend',
  onDragStart: 'dragstart',
  onMouseDown: 'mousedown',
  onMouseMove: 'mousemove',
  onMouseOut: 'mouseout',
  onMouseOver: 'mouseover',
  onMouseUp: 'mouseup',
  onRightClick: 'rightclick',
  onClick: 'click',
  onDrag: 'drag',
}

const updaterMap = {
  draggable (instance, draggable) {
    instance.setDraggable(draggable)
  },

  editable (instance, editable) {
    instance.setEditable(editable)
  },

  options (instance, options) {
    instance.setOptions(options)
  },

  path (instance, path) {
    instance.setPath(path)
  },

  visible (instance, visible) {
    instance.setVisible(visible)
  },
}
