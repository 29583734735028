/* istanbul ignore file */
import PropTypes from 'prop-types'
import React, { useState, useEffect } from 'react'

const LOADING_STATE_NONE = `NONE`
const LOADING_STATE_BEGIN = `BEGIN`
const LOADING_STATE_LOADED = `LOADED`

const canUseDOM = !!(
    (typeof window !== 'undefined' &&
    window.document && window.document.createElement)
)

const withScriptjs = (BaseComponent) => {
  const factory = React.createFactory(BaseComponent)

  // eslint-disable-line no-unused-vars
  const myContainer = ({ loadingElement, googleMapURL, ...restProps }) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [loadingState, setLoadingState] = useState(LOADING_STATE_NONE)

    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        if (loadingState !== LOADING_STATE_NONE || !canUseDOM) {
          return
        }
        setLoadingState(LOADING_STATE_BEGIN)
        // Don't load scriptjs as a dependency since we do not want this module be used on server side.
        // eslint-disable-next-line global-require
        const scriptjs = require(`scriptjs`)
        scriptjs(googleMapURL, () => {
          if (!loadingState) {
              return
            }
            setLoadingState(LOADING_STATE_LOADED)
        })
        return () => {
          setLoadingState(null)
        }
    }, [setLoadingState, loadingState, googleMapURL])

    if (loadingState === LOADING_STATE_LOADED) {
      return factory(restProps)
    } else {
      return loadingElement
    }
  }

  myContainer.propTypes = {
    loadingElement: PropTypes.node.isRequired,
    googleMapURL: PropTypes.string.isRequired,
  }

  return myContainer
}

export default withScriptjs
