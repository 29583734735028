/* istanbul ignore file */
import React from 'react'
import PropTypes from 'prop-types'
import { DotsLoader, Icon, Copy } from '@elparking/components'

const Loader = () => <Copy textAlign='center'><DotsLoader dotSize='8px' /></Copy>
const Empty = () => null

const Selector = ({
    dataTest,
    optionRenderer,
    valueRenderer,
    clearable,
    value,
    selectorComponent: SelectorComponent,
    loadingComponent: LoadingMessage,
    emptyComponent: NoOptionsMessage,
    clearComponent: ClearIndicator,
    arrowComponent: DropdownIndicator,
    separatorComponent: IndicatorSeparator,
    ...restProps
}) => <SelectorComponent
  data-test={dataTest}
  classNamePrefix='Select'
  components={{
    ClearIndicator,
    IndicatorSeparator,
    DropdownIndicator,
    LoadingMessage,
    NoOptionsMessage,
  }}
  defaultValue={value}
  value={value}
  isClearable={clearable}
  getOptionLabel={optionRenderer}
  formatOptionLabel={(optionRenderer && valueRenderer) ? (option, { context }) => {
    const optionValue = option.value.value
      ? option.value
      : option
    return (context === 'menu')
      ? optionRenderer(optionValue)
      : valueRenderer(optionValue)
  } : undefined }
  {...restProps}
/>

Selector.propTypes = {
    dataTest: PropTypes.string,
    optionRenderer: PropTypes.func,
    valueRenderer: PropTypes.func,
    clearable: PropTypes.bool,
    emptyComponent: PropTypes.func,
    loadingComponent: PropTypes.func,
    clearComponent: PropTypes.func,
    selectorComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.object]).isRequired,
    separatorComponent: PropTypes.func,
    arrowComponent: PropTypes.func,
    value: PropTypes.any,
}

Selector.defaultProps = {
  clearable: true,
  emptyComponent: Empty,
  loadingComponent: Loader,
  clearComponent: () => <Icon type='x' color='lightGray' />,
  arrowComponent: () => <Icon type='arrow-down' color='lightGray' />,
  separatorComponent: null,
  Loader: true,
}

export default Selector
