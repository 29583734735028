/* istanbul ignore file */
import PropTypes from 'prop-types'
import React from 'react'
import { warn } from '../../util/logger'
import { MAP } from './constants'

export function withGoogleMap (BaseComponent) {
  const factory = React.createFactory(BaseComponent)

  class Container extends React.PureComponent {
    static propTypes = {
      containerElement: PropTypes.node.isRequired,
      mapElement: PropTypes.node.isRequired,
    }

    static childContextTypes = {
      [MAP]: PropTypes.object,
    }

    state = {
      map: null,
    }

    getChildContext () {
      return {
        [MAP]: this.state.map,
      }
    }

    handleComponentMount (node) {
      if (this.state.map || node === null) {
        return
      }
      `undefined` !== typeof google && warn(
        `Make sure you've put a <script> tag in your <head> element to load Google Maps JavaScript API v3.
 If you're looking for built-in support to load it for you, use the "async/ScriptjsLoader" instead.
 See https://github.com/tomchentw/react-google-maps/pull/168`
      )
      // https://developers.google.com/maps/documentation/javascript/3.exp/reference#Map
      // eslint-disable-next-line no-undef
      const map = new google.maps.Map(node)
      this.setState({ map })
    }
    handleComponentMount = this.handleComponentMount.bind(this)

    render () {
      const { containerElement, mapElement, ...restProps } = this.props

      const { map } = this.state

      if (map) {
        return React.cloneElement(
          containerElement,
          {},
          React.cloneElement(mapElement, {
            ref: this.handleComponentMount,
          }),
            <div>{factory(restProps)}</div>
        )
      } else {
        return React.cloneElement(
          containerElement,
          {},
          React.cloneElement(mapElement, {
            ref: this.handleComponentMount,
          }),
            <div />
        )
      }
    }
  }

  return Container
}

export default withGoogleMap
