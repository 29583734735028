/* istanbul ignore file */
import React from 'react'
import PropTypes from 'prop-types'
import makeMarkerWithLabel from 'markerwithlabel'
import ReactDOM from 'react-dom'

import {
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
  construct,
} from './MapChildHelper'

import { MAP, MARKER_CLUSTERER, MARKER_WITH_LABEL } from './constants'

const markerWithLabelPropTypes = {
    children: PropTypes.node,
    labelAnchor: PropTypes.object,
    labelClass: PropTypes.string,
    labelStyle: PropTypes.object,
    labelVisible: PropTypes.bool,
    noRedraw: PropTypes.bool,
    defaultAnimation: PropTypes.any,
    defaultClickable: PropTypes.bool,
    defaultCursor: PropTypes.string,
    defaultDraggable: PropTypes.bool,
    defaultIcon: PropTypes.any,
    defaultLabel: PropTypes.any,
    defaultOpacity: PropTypes.number,
    defaultOptions: PropTypes.any,
    defaultPlace: PropTypes.any,
    defaultPosition: PropTypes.any,
    defaultShape: PropTypes.any,
    defaultTitle: PropTypes.string,
    defaultVisible: PropTypes.bool,
    defaultZIndex: PropTypes.number,
    animation: PropTypes.any,
    clickable: PropTypes.bool,
    cursor: PropTypes.string,
    draggable: PropTypes.bool,
    icon: PropTypes.any,
    label: PropTypes.any,
    opacity: PropTypes.number,
    options: PropTypes.any,
    place: PropTypes.any,
    position: PropTypes.any,
    shape: PropTypes.any,
    title: PropTypes.string,
    visible: PropTypes.bool,
    zIndex: PropTypes.number,
    onDblClick: PropTypes.func,
    onDragEnd: PropTypes.func,
    onDragStart: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseOut: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseUp: PropTypes.func,
    onRightClick: PropTypes.func,
    onAnimationChanged: PropTypes.func,
    onClick: PropTypes.func,
    onClickableChanged: PropTypes.func,
    onCursorChanged: PropTypes.func,
    onDrag: PropTypes.func,
    onDraggableChanged: PropTypes.func,
    onFlatChanged: PropTypes.func,
    onIconChanged: PropTypes.func,
    onPositionChanged: PropTypes.func,
    onShapeChanged: PropTypes.func,
    onTitleChanged: PropTypes.func,
    onVisibleChanged: PropTypes.func,
    onZindexChanged: PropTypes.func,
}

export class MarkerWithLabel extends React.PureComponent {
    static propTypes = {
        ...markerWithLabelPropTypes,
    }

    static defaultProps = {
        labelVisible: true,
    }

    static contextTypes = {
        [MAP]: PropTypes.object,
        [MARKER_CLUSTERER]: PropTypes.object,
    }

    constructor (props, context) {
        super(props, context)
        // eslint-disable-next-line no-undef
        const NativeMarkerWithLabel = makeMarkerWithLabel(google.maps)
        const markerWithLabel = new NativeMarkerWithLabel()
        construct(
            MarkerWithLabel.propTypes,
            updaterMap,
            this.props,
            markerWithLabel
        )
        const markerClusterer = this.context[MARKER_CLUSTERER]
        if (markerClusterer) {
            markerClusterer.addMarker(markerWithLabel, !!this.props.noRedraw)
        } else {
            markerWithLabel.setMap(this.context[MAP])
        }
        this.state = {
            [MARKER_WITH_LABEL]: markerWithLabel,
        }
    }

    componentDidMount () {
        componentDidMount(this, this.state[MARKER_WITH_LABEL], eventMap)
        const container = document.createElement(`div`)
        ReactDOM.unstable_renderSubtreeIntoContainer(
            this,
            React.Children.only(this.props.children),
            container
        )
        this.state[MARKER_WITH_LABEL].set(`labelContent`, container)
    }

    componentDidUpdate (prevProps) {
        componentDidUpdate(
            this,
            this.state[MARKER_WITH_LABEL],
            eventMap,
            updaterMap,
            prevProps
        )
        if (this.props.children !== prevProps.children) {
            ReactDOM.unstable_renderSubtreeIntoContainer(
                this,
                React.Children.only(this.props.children),
                this.state[MARKER_WITH_LABEL].get('labelContent')
            )
        }
    }

    componentWillUnmount () {
        componentWillUnmount(this)
        const markerWithLabel = this.state[MARKER_WITH_LABEL]
        if (markerWithLabel) {
        const markerClusterer = this.context[MARKER_CLUSTERER]
        if (markerClusterer) {
            markerClusterer.removeMarker(markerWithLabel, !!this.props.noRedraw)
        }
        if (markerWithLabel.get('labelContent')) {
            ReactDOM.unmountComponentAtNode(markerWithLabel.get('labelContent'))
        }
        markerWithLabel.setMap(null)
        }
    }

    render () {
        return false
    }

    getAnimation () {
        return this.state[MARKER_WITH_LABEL].getAnimation()
    }

    getClickable () {
        return this.state[MARKER_WITH_LABEL].getClickable()
    }

    getCursor () {
        return this.state[MARKER_WITH_LABEL].getCursor()
    }

    getDraggable () {
        return this.state[MARKER_WITH_LABEL].getDraggable()
    }

    getIcon () {
        return this.state[MARKER_WITH_LABEL].getIcon()
    }

    getLabel () {
        return this.state[MARKER_WITH_LABEL].getLabel()
    }

    getOpacity () {
        return this.state[MARKER_WITH_LABEL].getOpacity()
    }

    getPlace () {
        return this.state[MARKER_WITH_LABEL].getPlace()
    }

    getPosition () {
        return this.state[MARKER_WITH_LABEL].getPosition()
    }

    getShape () {
        return this.state[MARKER_WITH_LABEL].getShape()
    }

    getTitle () {
        return this.state[MARKER_WITH_LABEL].getTitle()
    }

    getVisible () {
        return this.state[MARKER_WITH_LABEL].getVisible()
    }

    getZIndex () {
        return this.state[MARKER_WITH_LABEL].getZIndex()
    }
}

export default MarkerWithLabel

const eventMap = {
    onDblClick: 'dblclick',
    onDragEnd: 'dragend',
    onDragStart: 'dragstart',
    onMouseDown: 'mousedown',
    onMouseOut: 'mouseout',
    onMouseOver: 'mouseover',
    onMouseUp: 'mouseup',
    onRightClick: 'rightclick',
    onAnimationChanged: 'animation_changed',
    onClick: 'click',
    onClickableChanged: 'clickable_changed',
    onCursorChanged: 'cursor_changed',
    onDrag: 'drag',
    onDraggableChanged: 'draggable_changed',
    onFlatChanged: 'flat_changed',
    onIconChanged: 'icon_changed',
    onPositionChanged: 'position_changed',
    onShapeChanged: 'shape_changed',
    onTitleChanged: 'title_changed',
    onVisibleChanged: 'visible_changed',
    onZindexChanged: 'zindex_changed',
}

const updaterMap = {
    labelAnchor (instance, labelAnchor) {
        instance.set(`labelAnchor`, labelAnchor)
    },

    labelClass (instance, labelClass) {
        instance.set(`labelClass`, labelClass)
    },

    labelStyle (instance, labelStyle) {
        instance.set(`labelStyle`, labelStyle)
    },

    labelVisible (instance, labelVisible) {
        instance.set(`labelVisible`, labelVisible)
    },

    animation (instance, animation) {
        instance.setAnimation(animation)
    },

    clickable (instance, clickable) {
        instance.setClickable(clickable)
    },

    cursor (instance, cursor) {
        instance.setCursor(cursor)
    },

    draggable (instance, draggable) {
        instance.setDraggable(draggable)
    },

    icon (instance, icon) {
        instance.setIcon(icon)
    },

    label (instance, label) {
        instance.setLabel(label)
    },

    opacity (instance, opacity) {
        instance.setOpacity(opacity)
    },

    options (instance, options) {
        instance.setOptions(options)
    },

    place (instance, place) {
        instance.setPlace(place)
    },

    position (instance, position) {
        instance.setPosition(position)
    },

    shape (instance, shape) {
        instance.setShape(shape)
    },

    title (instance, title) {
        instance.setTitle(title)
    },

    visible (instance, visible) {
        instance.setVisible(visible)
    },

    zIndex (instance, zIndex) {
        instance.setZIndex(zIndex)
    },
}
