/* istanbul ignore file */
import React from 'react'
import PropTypes from 'prop-types'
import withScriptjs from './MapUtils/withScriptjs'
import withMap from './MapUtils/withMap'
import GoogleMap from './MapUtils/GoogleMap'
import Marker from './MapUtils/Marker'
import Polyline from './MapUtils/Polyline'
import Polygon from './MapUtils/Polygon'
import MarkerWithLabel from './MapUtils/MarkerWithLabel'
import InfoBox from './MapUtils/InfoBox'
import { getConstant } from 'commons/js/constants'

const Map = React.forwardRef(({children, ...props}, ref) =>
    <GoogleMap
      ref={ref}
      {...props}
    >
        {children}
    </GoogleMap>
)

Map.propTypes = {
    children: PropTypes.node,
}

export const googleMapURL = `https://maps.googleapis.com/maps/api/js?v=3&libraries=places,geometry&key=${getConstant('GOOGLEMAPS_API_KEY')}&callback=Function.prototype&language=${getConstant('LANGUAGE')}&region=${getConstant('COUNTRY_ISO2')}`

export {
    Map,
    withMap,
    InfoBox,
    MarkerWithLabel,
    Marker,
    Polyline,
    Polygon,
    withScriptjs,
}
