/* istanbul ignore file */
import React from 'react'
import PropTypes from 'prop-types'

import {
  construct,
  componentDidMount,
  componentDidUpdate,
  componentWillUnmount,
} from './MapChildHelper'

import { MAP, POLYGON } from './constants'

const poligonPropTypes = {
    defaultDraggable: PropTypes.bool,
    defaultEditable: PropTypes.bool,
    defaultOptions: PropTypes.any,
    defaultPath: PropTypes.any,
    defaultPaths: PropTypes.any,
    defaultVisible: PropTypes.bool,
    draggable: PropTypes.bool,
    editable: PropTypes.bool,
    options: PropTypes.any,
    path: PropTypes.any,
    paths: PropTypes.any,
    visible: PropTypes.bool,
    onDblClick: PropTypes.func,
    onDragEnd: PropTypes.func,
    onDragStart: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseMove: PropTypes.func,
    onMouseOut: PropTypes.func,
    onMouseOver: PropTypes.func,
    onMouseUp: PropTypes.func,
    onRightClick: PropTypes.func,
    onClick: PropTypes.func,
    onDrag: PropTypes.func,
}

export class Polygon extends React.PureComponent {
    static poligonPropTypes = {
        ...poligonPropTypes,
    }

    static contextTypes = {
        [MAP]: PropTypes.object,
    }

    constructor (props, context) {
        super(props, context)
        // eslint-disable-next-line no-undef
        const polygon = new google.maps.Polygon()
        construct(Polygon.propTypes, updaterMap, this.props, polygon)
        polygon.setMap(this.context[MAP])
        this.state = {
        [POLYGON]: polygon,
        }
    }

    componentDidMount () {
        componentDidMount(this, this.state[POLYGON], eventMap)
    }

    componentDidUpdate (prevProps) {
        componentDidUpdate(
        this,
        this.state[POLYGON],
        eventMap,
        updaterMap,
        prevProps
        )
    }

    componentWillUnmount () {
        componentWillUnmount(this)
        const polygon = this.state[POLYGON]
        if (polygon) {
        polygon.setMap(null)
        }
    }

    render () {
        return false
    }

    getDraggable () {
        return this.state[POLYGON].getDraggable()
    }

    getEditable () {
        return this.state[POLYGON].getEditable()
    }

    getPath () {
        return this.state[POLYGON].getPath()
    }

    getPaths () {
        return this.state[POLYGON].getPaths()
    }

    getVisible () {
        return this.state[POLYGON].getVisible()
    }
}

export default Polygon

const eventMap = {
    onDblClick: 'dblclick',
    onDragEnd: 'dragend',
    onDragStart: 'dragstart',
    onMouseDown: 'mousedown',
    onMouseMove: 'mousemove',
    onMouseOut: 'mouseout',
    onMouseOver: 'mouseover',
    onMouseUp: 'mouseup',
    onRightClick: 'rightclick',
    onClick: 'click',
    onDrag: 'drag',
}

const updaterMap = {
    draggable (instance, draggable) {
        instance.setDraggable(draggable)
    },

    editable (instance, editable) {
        instance.setEditable(editable)
    },

    options (instance, options) {
        instance.setOptions(options)
    },

    path (instance, path) {
        instance.setPath(path)
    },

    paths (instance, paths) {
        instance.setPaths(paths)
    },

    visible (instance, visible) {
        instance.setVisible(visible)
    },
}
