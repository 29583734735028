/* istanbul ignore file */
function rdcUncontrolledAndControlledProps (acc, key) {
    if (acc.prevProps[key]) {
      const match = key.match(/^default(\S+)/)
      if (match) {
        const unprefixedKey = match[1][0].toLowerCase() + match[1].substring(1)
        if (acc.nextProps.includes(unprefixedKey)) {
          acc.nextProps[unprefixedKey] = acc.prevProps[key]
        }
      } else {
        acc.nextProps[key] = acc.prevProps[key]
      }
    }
    return acc
}

function applyUpdaterToNextProps (updaterMap, prevProps, nextProps, instance) {
    Object.keys(updaterMap).forEach((key) => {
      const fn = updaterMap[key]
      const nextValue = nextProps[key]
      if (nextValue !== prevProps[key]) {
        fn(instance, nextValue)
      }
    })
}

export function construct (propTypes, updaterMap, prevProps, instance) {
    const { nextProps } = Object.keys(propTypes).reduce(rdcUncontrolledAndControlledProps, {
      nextProps: {},
      prevProps,
    })
    applyUpdaterToNextProps(
      updaterMap,
      {
        /* empty prevProps for construct */
      },
      nextProps,
      instance
    )
}

export function componentDidMount (component, instance, eventMap) {
    registerEvents(component, instance, eventMap)
}

export function componentDidUpdate (
    component,
    instance,
    eventMap,
    updaterMap,
    prevProps
) {
    component.unregisterAllEvents()
    applyUpdaterToNextProps(updaterMap, prevProps, component.props, instance)
    registerEvents(component, instance, eventMap)
}

export function componentWillUnmount (component) {
    component.unregisterAllEvents()
}

function registerEvents (component, instance, eventMap) {
    const registeredList = Object.keys(eventMap).reduce(
      (acc, onEventName) => {
        const googleEventName = eventMap[onEventName]
        if (component.props[onEventName] instanceof Function) {
          acc.push(
            // eslint-disable-next-line no-undef
            google.maps.event.addListener(
              instance,
              googleEventName,
              component.props[onEventName]
            )
          )
        }
        return acc
      },
      []
    )
    component.unregisterAllEvents = () => registeredList.forEach(unregisterEvent)
}

function unregisterEvent (registered) {
  // eslint-disable-next-line no-undef
  google.maps.event.removeListener(registered)
}
